(function ($) {
    var scrollLoad = false;
    $(window).on('load', function () {
        $(window).on('scroll', function (event) {
            if ($(window).scrollTop() > 10) {
                if (!scrollLoad) {
                    /*$('.home-banner-slider').slick({
                      fade: true,
                      autoplay: true,
                      autoplaySpeed: 6000,
                      adaptiveHeight: true,
                      dots: false,
                      arrows: false,
                      pauseOnHover: true,
                    });*/
                    $('.home-blog-nav-slider').slick({
                        swipeToSlide: true,
                        // infinite: false,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        swipeToSlide: true,
                        arrows: true,
                        appendArrows: $('.home-blog-nav'),
                        dots: false,
                        speed: 300,
                        //   asNavFor: '.home-blog-content-slider',
                        //   focusOnSelect: true,
                        responsive: [{
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3
                            }
                        }, {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 2
                            }
                        }, {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1
                            }
                        }]
                    }).on('setPosition', function (event, slick) {
                        if ($('.home-blog-nav .slick-arrow').length == 0) {
                            $('.home-blog-nav').addClass('no-arrow');
                        } else {
                            $('.home-blog-nav').removeClass('no-arrow');
                        }
                    }).on('click', '.bt', function (e) {
                        var index = $(this).data('index');
                        $('.home-blog-nav-slider .bt').removeClass('current');
                        $(this).addClass('current');
                        $('.home-blog-content-slider').slick('slickGoTo', index)
                    });
                    $('.home-blog-content-slider').slick({
                        //   adaptiveHeight: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        fade: true,
                        swipe: false,
                        //   asNavFor: '.home-blog-nav-slider'
                    });
                    $('.home-case-nav-slider').slick({
                        swipeToSlide: true,
                        // infinite: false,
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        swipeToSlide: true,
                        arrows: true,
                        appendArrows: $('.home-case-nav'),
                        dots: false,
                        speed: 300,
                        //   asNavFor: '.home-case-content-slider',
                        //   focusOnSelect: true,
                        responsive: [{
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 5
                            }
                        }, {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 4
                            }
                        }, {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 3
                            }
                        }, {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2
                            }
                        }, {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1
                            }
                        }]
                    }).on('setPosition', function (event, slick) {
                        if ($('.home-case-nav .slick-arrow').length == 0) {
                            $('.home-case-nav').addClass('no-arrow');
                        } else {
                            $('.home-case-nav').removeClass('no-arrow');
                        }
                    }).on('click', '.bt', function (e) {
                        var index = $(this).data('index');
                        $('.home-case-nav-slider .bt').removeClass('current');
                        $(this).addClass('current');
                        $('.home-case-content-slider').slick('slickGoTo', index)
                    });
                    $('.home-case-content-slider').slick({
                        //   adaptiveHeight: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        fade: true,
                        swipe: false,
                        //   asNavFor: '.home-case-nav-slider'
                    }).on('setPosition', function (event, slick) {
                        $('.home-case-slider .home-case-box').css('min-height', 0)
                        var arr = [];
                        $('.home-case-box').each(function (i) {
                            arr.push($(this).outerHeight());
                        })
                        var maxH = Math.max(...arr);
                        // console.log(arr, maxH)
                        $('.home-case-slider .home-case-box').css('min-height', maxH)
                    });
                    $('.home-case-slider').slick({
                        //   adaptiveHeight: true,
                        fade: true,
                        dots: false,
                        arrows: true,
                        pauseOnHover: true,
                    }).on('setPosition', function (event, slick) {
                        var caseSlider = $('.home-case-content-slider').slick('getSlick');
                        setTimeout(function () {
                            caseSlider.setPosition();
                        }, 300);
                    });
                    $('.home-blog-content-slider .slick-slide:not(.slick-cloned) .collapse').each(function (index, elem) {
                        elem.addEventListener('hidden.bs.collapse', function (event) {
                            //   console.log(event.target);
                            var blogSlider = $('.home-blog-content-slider').slick('getSlick');
                            blogSlider.setPosition();
                            //   console.log(blogSlider, blogSlider.$list);
                        });
                        elem.addEventListener('shown.bs.collapse', function (event) {
                            //   console.log(event.target);
                            var blogSlider = $('.home-blog-content-slider').slick('getSlick');
                            blogSlider.setPosition();
                        });
                    });
                }
                scrollLoad = true;
            }
        }).scroll();
    });
})($)
